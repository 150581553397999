var render, staticRenderFns
import script from "./Parser.vue?vue&type=script&lang=js"
export * from "./Parser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/superior_admin_ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d6165874')) {
      api.createRecord('d6165874', component.options)
    } else {
      api.reload('d6165874', component.options)
    }
    
  }
}
component.options.__file = "src/components/FormGenerator/components/parser/Parser.vue"
export default component.exports